var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-main",
    { staticClass: "main_class" },
    [
      _c(
        "v-container",
        {
          staticClass: "d-flex justify-space-between",
          attrs: { fluid: "", "fill-height": "" },
        },
        [
          _c(
            "v-row",
            { attrs: { "no-gutters": "", justify: "center" } },
            [
              _c(
                "v-col",
                { attrs: { xl: "4", lg: "5", md: "6", sm: "14", xs: "14" } },
                [
                  _c(
                    "v-card",
                    [
                      _c(
                        "v-card-text",
                        [
                          _c("v-img", {
                            staticClass: "logo_class",
                            attrs: {
                              src: require("@/assets/AileronLogoColor.png"),
                            },
                            on: { click: _vm.aileronRedirect },
                          }),
                          _c("h2", { staticClass: "text-center mb-5" }, [
                            _vm._v(_vm._s(_vm.pageTitle)),
                          ]),
                          _c("v-divider", { staticClass: "mb-5" }),
                          _c(
                            "v-main",
                            [
                              _c(
                                "v-fade-transition",
                                { attrs: { mode: "out-in" } },
                                [_c("router-view")],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }