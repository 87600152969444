var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-row",
    { attrs: { "align-content": "center", justify: "space-around" } },
    [
      _c(
        "v-btn",
        {
          attrs: {
            href: "https://shop.aileron.org/login?ReturnURL=%2Fsearch%3Fst_t%3D2078%26st_ti%3D2521",
            height: "100",
            tile: "",
            dark: "",
            depressed: "",
            color: "menu",
          },
        },
        [
          _c(
            "v-container",
            [
              _c(
                "v-row",
                {
                  staticClass: "mb-3",
                  attrs: { "align-content": "center", justify: "center" },
                },
                [
                  _c("v-icon", { attrs: { large: "" } }, [
                    _vm._v("far fa-calendar-alt"),
                  ]),
                ],
                1
              ),
              _c("v-row", [_c("h3", [_vm._v("Reservations")])]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-btn",
        {
          attrs: {
            href: "https://shop.aileron.org/login?ReturnURL=%2Fsearch%3Fst_t%3D2119%26st_ti%3D2759",
            height: "100",
            tile: "",
            dark: "",
            depressed: "",
            color: "menu",
          },
        },
        [
          _c(
            "v-container",
            [
              _c(
                "v-row",
                {
                  staticClass: "mb-3",
                  attrs: { "align-content": "center", justify: "center" },
                },
                [
                  _c("v-icon", { attrs: { large: "" } }, [
                    _vm._v("fas fa-user-graduate"),
                  ]),
                ],
                1
              ),
              _c("v-row", [_c("h3", [_vm._v("Registrations")])]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-btn",
        {
          attrs: {
            href: _vm.contentPortalUrl,
            height: "100",
            tile: "",
            dark: "",
            depressed: "",
            color: "menu",
          },
        },
        [
          _c(
            "v-container",
            [
              _c(
                "v-row",
                {
                  staticClass: "mb-3",
                  attrs: { "align-content": "center", justify: "center" },
                },
                [
                  _c("v-icon", { attrs: { large: "" } }, [
                    _vm._v("fas fa-book"),
                  ]),
                ],
                1
              ),
              _c("v-row", [_c("h3", [_vm._v("Content Portal")])]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }