var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-main",
    { staticClass: "main_class" },
    [
      _c(
        "v-container",
        { attrs: { fluid: "", "fill-height": "" } },
        [_c("router-view")],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }