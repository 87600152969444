var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.isMounted
    ? _c(
        "v-card",
        { attrs: { flat: "" } },
        [
          _c(
            "v-card-text",
            [
              _c(
                "ValidationObserver",
                { ref: "observer", attrs: { slim: "" } },
                [
                  _c(
                    "v-form",
                    {
                      attrs: {
                        action: "identityServerRedirectUrl",
                        method: "post",
                      },
                      model: {
                        value: _vm.loginFormValid,
                        callback: function ($$v) {
                          _vm.loginFormValid = $$v
                        },
                        expression: "loginFormValid",
                      },
                    },
                    [
                      _c(
                        "form",
                        { ref: "signupForm", attrs: { method: "POST" } },
                        [
                          _c("input", {
                            attrs: {
                              id: "tocommonauth",
                              name: "tocommonauth",
                              type: "hidden",
                              value: "true",
                            },
                          }),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.sessionDataKey,
                                expression: "sessionDataKey",
                              },
                            ],
                            attrs: { name: "sessionDataKey", type: "hidden" },
                            domProps: { value: _vm.sessionDataKey },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.sessionDataKey = $event.target.value
                              },
                            },
                          }),
                          _c("input", {
                            ref: "idpUsername",
                            attrs: { name: "username", type: "hidden" },
                          }),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.password,
                                expression: "password",
                              },
                            ],
                            attrs: { name: "password", type: "hidden" },
                            domProps: { value: _vm.password },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.password = $event.target.value
                              },
                            },
                          }),
                          _c("input", {
                            ref: "idpRememberMe",
                            attrs: { name: "chkRemember", type: "hidden" },
                          }),
                          _c(
                            "v-row",
                            { attrs: { "no-gutters": "" } },
                            [
                              _c(
                                "v-col",
                                [
                                  _c("ValidationProvider", {
                                    attrs: {
                                      rules: "email|registeredEmail|required",
                                      slim: "",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function ({ errors, valid }) {
                                            return [
                                              _c("v-text-field", {
                                                attrs: {
                                                  label: "Email",
                                                  success: valid,
                                                  "error-messages": errors,
                                                  "prepend-inner-icon":
                                                    "fas fa-envelope",
                                                  required: "",
                                                  type: "text",
                                                  name: "email",
                                                  outlined: "",
                                                  dense: "",
                                                },
                                                on: {
                                                  keydown: _vm.emailChanged,
                                                },
                                                model: {
                                                  value: _vm.email,
                                                  callback: function ($$v) {
                                                    _vm.email = $$v
                                                  },
                                                  expression: "email",
                                                },
                                              }),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      2495262723
                                    ),
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            { attrs: { "no-gutters": "" } },
                            [
                              _c(
                                "v-col",
                                [
                                  _c(
                                    "v-row",
                                    { attrs: { "no-gutters": "" } },
                                    [
                                      _c(
                                        "v-col",
                                        [
                                          _c("ValidationProvider", {
                                            attrs: {
                                              rules: "required",
                                              slim: "",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function ({
                                                    errors,
                                                    valid,
                                                  }) {
                                                    return [
                                                      _c("v-text-field", {
                                                        attrs: {
                                                          label: "Password",
                                                          "error-messages":
                                                            errors,
                                                          success: valid,
                                                          "prepend-inner-icon":
                                                            "fas fa-user-lock",
                                                          dense: "",
                                                          type: "password",
                                                          name: "userPassword",
                                                          hint: "At least 8 characters",
                                                          value: "",
                                                          outlined: "",
                                                        },
                                                        on: {
                                                          keydown:
                                                            _vm.passwordChanged,
                                                        },
                                                        model: {
                                                          value: _vm.password,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.password = $$v
                                                          },
                                                          expression:
                                                            "password",
                                                        },
                                                      }),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              false,
                                              3414695598
                                            ),
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    {
                                      attrs: {
                                        "no-gutters": "",
                                        justify: "space-between",
                                        "align-content": "start",
                                      },
                                    },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "auto" } },
                                        [
                                          _c("v-checkbox", {
                                            staticClass: "ma-0 pa-0",
                                            attrs: {
                                              dense: "",
                                              label: "Remember Me",
                                            },
                                            model: {
                                              value: _vm.rememberMe,
                                              callback: function ($$v) {
                                                _vm.rememberMe = $$v
                                              },
                                              expression: "rememberMe",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "auto" } },
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass: "forgotPassword",
                                              attrs: { text: "" },
                                              on: { click: _vm.recoverAccount },
                                            },
                                            [_vm._v("Forget Password?")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm.authError
                            ? _c(
                                "v-row",
                                {
                                  attrs: {
                                    justify: "center",
                                    "no-gutters": "",
                                  },
                                },
                                [
                                  _c(
                                    "p",
                                    {
                                      staticClass:
                                        "body-1 text--darken-3 red--text",
                                    },
                                    [_vm._v(_vm._s(_vm.authErrorMessage))]
                                  ),
                                  _vm.showResendConfirmation
                                    ? _c(
                                        "v-btn",
                                        {
                                          attrs: {
                                            dark: "",
                                            color: "secondary",
                                          },
                                          on: { click: _vm.recoverAccount },
                                        },
                                        [_vm._v("Resend Confirmation Email")]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-card-actions",
            [
              _c(
                "v-container",
                [
                  _c(
                    "v-row",
                    {
                      attrs: {
                        "align-content": "center",
                        justify: "center",
                        "no-gutters": "",
                      },
                    },
                    [
                      _c(
                        "v-col",
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "buttonHover",
                              attrs: {
                                loading: _vm.signingIn,
                                disabled:
                                  !_vm.loginFormValid ||
                                  _vm.email.length === 0 ||
                                  _vm.password.length === 0,
                                block: "",
                                dark: "",
                                depressed: "",
                                color: "action2",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.signIn()
                                },
                              },
                            },
                            [_vm._v("Login")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [_c("v-divider", { attrs: { color: "#074975" } })],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { attrs: { "no-gutters": "", justify: "center" } },
                    [
                      _c("v-col", { attrs: { cols: "auto" } }, [
                        _c("p", { staticClass: "secondaryActionBlock" }, [
                          _vm._v("Are you new to Aileron?"),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    {
                      attrs: {
                        "no-gutters": "",
                        "align-content": "center",
                        justify: "space-around",
                      },
                    },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "auto" } },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "buttonHover",
                              attrs: { outlined: "", color: "action2" },
                              on: { click: _vm.selfRegistration },
                            },
                            [_vm._v("Create Account")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }